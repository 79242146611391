import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransactionService } from 'src/app/services/api/transactions/transaction.service';
import { Country, State } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'create-wallet-form',
  templateUrl: './create-wallet-form.component.html',
  styleUrls: ['./create-wallet-form.component.scss'],
})
export class CreateWalletFormComponent {
  @Input() modalId: string = '';

  success: boolean = false;

  showFundWalletFields: boolean = false;

  modalId2: string = 'createWalletForm';

  window: any = window;

  @Output() emitChangeCountry: EventEmitter<string> =
    new EventEmitter<string>();

  @Input() countries: Country[] = [];

  @Input() states: State[] = [];

  cities: Array<any> = [];

  walletForm: FormGroup = new FormGroup({
    bvn: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    addressLine_1: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
  });

  verificationURL: string = '';
  step: number = 1;

  constructor(
    public globals: GlobalsService,
    public transactionService: TransactionService
  ) {
    this.verificationURL = environment.verificationURL;
  }

  ngOnChanges() {
    this.countries = this.countries.filter(
      (country) => country.name === 'Nigeria'
    );
    if (!this.walletForm.value.bvn)
      this.walletForm.patchValue({
        bvn: this.globals.user?.bvn || '',
      });
  }

  onBack() {
    this.step--;
  }

  onNext() {
    this.step++;
  }

  async onSubmit() {
    this.walletForm.value.currency = this.transactionService.selectedCurrency;
    await this.transactionService.createWallet(this.walletForm.value);
    await this.transactionService.getAllWallets();
    this.success = true;
  }

  setCountry(country: Country) {
    this.emitChangeCountry.emit(country._id);
    this.walletForm.patchValue({
      ...this.walletForm.value,
      country: country.name,
    });
  }

  setCity(city: any) {
    this.walletForm.patchValue({ city: city.name });
  }

  setState(state: State) {
    this.walletForm.patchValue({ state: state.name });
    this.cities = state.cities;
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  toggleFundWalletFields() {
    this.success = false;
    this.showFundWalletFields = true;
  }
}
