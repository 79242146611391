<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 mb-2">
      <img
        loading="lazy"
        src="assets/icons/warning-with-inner-circle-2.svg"
        alt=""
      />
    </div>
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Change Plan
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-3">
      <p class="mb-0 text-muted">
        Read to switch to monthly renewal on your Pro subscription? If so, your
        subscription will renew for $16 on January 28th, 2024.
      </p>

      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          data-bs-toggle="modal"
          data-bs-target="#changePlanModal"
          class="submit-btn fw-700 font-13 btn-decline"
          (click)="closeBtn.click()"
        >
          Go Back
        </button>
        <button
          type="submit"
          class="submit-btn fw-700 font-13"
          (click)="closeBtn.click()"
        >
          Update Billing Plan
        </button>
      </div>
    </div>
  </div>
</swap-modal>
