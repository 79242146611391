import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent {
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() customValue: boolean = false;
  @Output() emitValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitDate: EventEmitter<string> = new EventEmitter<string>();
  @Input() buttonLabel: string = '';
  @Input() style: Object = {};
  @Input() calendarStyle: Object = {};
  @Input() class: string = '';
  @Input() placeholder: string = '';

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  days: any[] = [];
  currentDate: string = '';
  currentMonth: number = new Date().getMonth();
  currentYear: number = new Date().getFullYear() - 1;
  currentDay: number = new Date().getDate();

  firstDayOfMonth: number = 0;
  lastDayOfMonth: number = 0;
  lastDateOfMonth: number = 0;
  lastDateOfPreviousMonth: number = 0;

  showCalendar: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.renderCalendar();
  }

  renderCalendar() {
    this.currentDate = `${
      this.currentDay < 10 ? '0' + this.currentDay : this.currentDay
    }/${
      this.currentMonth + 1 < 10
        ? '0' + Number(this.currentMonth + 1)
        : Number(this.currentMonth + 1)
    }/${this.currentYear + 1}`;

    // this.value = this.currentDate;

    this.firstDayOfMonth = new Date(
      this.currentYear,
      this.currentMonth,
      1
    ).getDay();
    this.lastDayOfMonth = new Date(
      this.currentYear,
      this.currentMonth + 1,
      0
    ).getDay();
    this.lastDateOfMonth = new Date(
      this.currentYear,
      this.currentMonth + 1,
      0
    ).getDate();
    this.lastDateOfPreviousMonth = new Date(
      this.currentYear,
      this.currentMonth,
      0
    ).getDate();

    this.days = [];

    for (let i = 0; i < this.firstDayOfMonth; i++) {
      this.days.push({
        date: this.lastDateOfPreviousMonth - i,
        disabled: true,
        today: false,
      });
    }

    for (let i = 0; i < this.lastDateOfMonth; i++) {
      this.days.push({
        date: i + 1,
        disabled: false,
        today:
          this.currentDay === i + 1 &&
          this.currentMonth === new Date().getMonth() &&
          this.currentYear === new Date().getFullYear() - 1
            ? true
            : this.currentDate ===
              `${i + 1 < 10 ? '0' + (i + 1) : i + 1}/${
                this.currentMonth + 1 < 10
                  ? '0' + Number(this.currentMonth + 1)
                  : Number(this.currentMonth + 1)
              }/${this.currentYear + 1}`
            ? true
            : false,
      });
    }

    for (let i = 0; i < 6 - this.lastDayOfMonth; i++) {
      this.days.push({
        date: i + 1,
        disabled: true,
        today: false,
      });
    }
  }

  selectDropDown(event: any) {
    // close other dropdown options
    event.preventDefault();
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (el.classList.contains('show') && el !== event.target.children[0]) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    event.target.children[0]?.classList.toggle('show');
    event.target.classList.toggle('show');

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target.classList.contains('dropdown-option')) {
        event.target.children[0]?.classList.remove('show');
        event.target.classList.remove('show');
      }
    });
  }

  setDate(day: number) {
    this.currentDay = day;
    let date = `${this.currentYear + 1}/${
      this.currentMonth < 10
        ? '0' + (this.currentMonth + 1)
        : this.currentMonth + 1
    }/${this.currentDay < 10 ? '0' + this.currentDay : this.currentDay}`;
    if (!this.customValue) this.value = date;
    this.emitValue.emit(date);
    this.renderCalendar();
  }

  submitDate(): void {
    this.showCalendar = false;
    const date = `${this.currentYear + 1}-${this.padNumber(this.currentMonth + 1)}-${this.padNumber(this.currentDay)}`;
    this.emitDate.emit(date);
  }

  private padNumber(number: number): string {
    return number < 9 ? `0${number}` : String(number);
  }

  cancelDate() {
    this.showCalendar = false;
    this.currentDay = new Date().getDate();
    this.currentMonth = new Date().getMonth();
    this.currentYear = new Date().getFullYear() - 1;
    this.renderCalendar();
  }

  prevMonth() {
    if (this.currentMonth === 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else {
      this.currentMonth--;
    }

    this.renderCalendar();
  }

  nextMonth() {
    if (this.currentMonth === 11) {
      this.currentMonth = 0;
      this.currentYear++;
    } else {
      this.currentMonth++;
    }
    this.renderCalendar();
  }

  closeCalendar() {
    document.addEventListener('mousedown', (e: any) => {
      if (e.target?.classList.contains('date-input')) {
        this.showCalendar = false;
      }
    });
  }

}
