import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(itemList: any[], searchTerm: string, keys?: string[]): any[] {
    if (!itemList || !searchTerm || !keys) return itemList;

    return itemList.filter((item) => {
      return keys.some((key) => {
        const parts = key.split('.');
        let value = item;
        for (const part of parts) {
          if (value && value.hasOwnProperty(part)) {
            value = value[part];
          } else {
            return false;
          }
        }
        return value
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase());
      });
    });
  }
}
