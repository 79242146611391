import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'topup-wallet',
  templateUrl: './topup-wallet.component.html',
  styleUrls: ['./topup-wallet.component.scss'],
})
export class TopupWalletComponent {
  @Input() modalId: string = '';

  @Input() walletId: string = '';

  @Input() currency: { country: string; code: string; iso2: string } | null =
    null;

  @Input() offerAmount: number = 0;

  @Output() emitTopupWallet: EventEmitter<any> = new EventEmitter<any>();

  topupWalletForm: FormGroup = new FormGroup({
    amount: new FormControl(
      0,
      Validators.compose([Validators.required, Validators.min(1)])
    ),
    purpose: new FormControl('', Validators.compose([Validators.required])),
    type: new FormControl(''),
    wallet: new FormControl('', Validators.compose([Validators.required])),
    currency: new FormControl(null, Validators.compose([Validators.required])),
  });

  transactionPurposeList: string[] = ['Escrow funding'];

  constructor(public globals: GlobalsService) {}

  ngOnChanges() {
    if (
      !this.topupWalletForm.value.wallet ||
      !this.topupWalletForm.value.currency
    ) {
      this.resetParams();
    }

    if (this.offerAmount) {
      this.topupWalletForm.patchValue({
        amount: this.formatCurrency(this.offerAmount),
      });
    }
  }

  async onSubmit() {
    if (this.topupWalletForm.valid) {
      this.topupWalletForm.patchValue({
        amount: this.formatToNumber(this.topupWalletForm.value.amount),
      });
      this.emitTopupWallet.emit(this.topupWalletForm.value);
      this.resetParams();
    }
  }

  formatAmount(event: any, type: string) {
    // format the amount to have commas and decimals
    let amount = event.target.value;
    amount = this.formatCurrency(amount);
    this.topupWalletForm.patchValue({
      [type]: amount,
    });
    // trigger validation
    this.topupWalletForm.controls[type].markAsTouched();
    this.topupWalletForm.controls[type].markAsDirty();
    this.topupWalletForm.updateValueAndValidity();
  }

  formatCurrency(value: number) {
    let amount = value.toString();
    amount = amount.replace(/,/g, '');
    amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    // allow only numbers, commas and decimals
    amount = amount.replace(/[^0-9,\.]/g, '');
    return amount;
  }
  formatToNumber(amount: string | number): number {
    if (typeof amount === 'number') return amount;
    return Number(amount?.replace(/,/g, '') || 0);
  }

  startDropDown(id: string) {
    let dropDown = document.querySelector(`.${id}`) as HTMLElement;
    dropDown.classList.toggle('active');
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
      }
    });
  }

  selectPurpose(purpose: string) {
    this.topupWalletForm.patchValue({
      purpose: purpose,
    });
  }

  resetParams() {
    this.topupWalletForm.reset({
      amount: 0,
      purpose: 'Escrow funding',
      type: 'credit',
      wallet: this.walletId,
      currency: this.currency,
    });
  }

  get amount() {
    return this.topupWalletForm.get('amount') as FormControl;
  }

  get purpose() {
    return this.topupWalletForm.get('purpose') as FormControl;
  }

  get tranx_ref() {
    return this.topupWalletForm.get('tranx_ref') as FormControl;
  }
}
