<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3">
    <h2 class="modal-title fs-5 fw-bolder" id="staticBackdropLabel">
      Verify Social
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>

  <div class="modal-body mx-3">
    <section>
      <div class="fw-400 mb-4 font-16">
        To enhance your profile and foster trust among our users, we kindly
        request you to verify your social accounts.
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-2 align-items-center">
            <img
              loading="lazy"
              style="width: 21px"
              src="assets/icons/facebook.svg"
              alt="facebook"
            />
            <div>
              <div class="font-14 fw-700">Facebook</div>
              <div class="font-12 fw-400">Not connected</div>
            </div>
          </div>
          <button class="py-2 px-3 font-12 fw-700 border-0 con-btn">
            Connect
          </button>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-2 align-items-center">
            <img
              loading="lazy"
              style="width: 21px"
              src="assets/icons/twitter.svg"
              alt="facebook"
            />
            <div>
              <div class="font-14 fw-700">Twitter</div>
              <div class="font-12 fw-400">Not connected</div>
            </div>
          </div>
          <button class="py-2 px-3 font-12 fw-700 border-0 con-btn">
            Connect
          </button>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex gap-2 align-items-center">
            <img
              loading="lazy"
              style="width: 21px"
              src="assets/icons/instagram.svg"
              alt="facebook"
            />
            <div>
              <div class="font-14 fw-700">Instagram</div>
              <div class="font-12 fw-400">Not connected</div>
            </div>
          </div>
          <button class="py-2 px-3 font-12 fw-700 border-0 con-btn">
            Connect
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-center gap-4 mt-5">
        <button class="back-btn px-3 font-16 fw-700 py-2 w-100 border-0">
          Go Back
        </button>
        <button class="px-4 py-2 font-16 fw-700 btn-upgrade w-100 border-0">
          Done
        </button>
      </div>
    </section>
  </div>
</swap-modal>
