<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header mb-4">
    <p class="modal-title fw-bolder" id="staticBackdropLabel">Token Details</p>
    <button
      type="button"
      class="closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
    >
      <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
    </button>
  </div>
  <div class="modal-body m-3 mt-0 pt-0">
    <!-- Nav tabs -->
    <ul class="nav nav-pills" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [class.active]="currentTab == 'bonus'"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          (click)="currentTab = 'bonus'; currentTabChange.emit(currentTab)"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Bonus
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [class.active]="currentTab == 'purchased'"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          (click)="currentTab = 'purchased'; currentTabChange.emit(currentTab)"
          aria-controls="profile"
          aria-selected="false"
        >
          Purchased
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [class.active]="currentTab == 'subscription'"
          id="messages-tab"
          data-bs-toggle="tab"
          data-bs-target="#messages"
          type="button"
          role="tab"
          (click)="
            currentTab = 'subscription'; currentTabChange.emit(currentTab)
          "
          aria-controls="messages"
          aria-selected="false"
        >
          Subscription
        </button>
      </li>
    </ul>

    <div class="d-flex align-items-center justify-content-between my-5 mb-3">
      <p class="fw-bolder mb-0">
        {{ currentTab | titlecase }}
      </p>
      <button class="filter-btn" (click)="selectDropdown($event)">
        <img
          loading="lazy"
          src="assets/icons/filter-icon.svg"
          alt=""
          class="me-2"
        />
        Filters

        <div class="dropdown-options">
          <a
            class="dropdown-option text-decoration-none font-14"
            *ngFor="let filter of filterOptions"
            (click)="selectFilter(filter)"
          >
            {{ filter.name }}</a
          >
          <a
            class="dropdown-option text-decoration-none font-14"
            href="javascript:void(0)"
            (click)="triggerCalendar()"
          >
            Custom</a
          >
        </div>
        <div class="calendar-dropdown">
          <div class="row flex-nowrap gap-3 mb-3 align-items-center">
            <div class="col-5 text-start">
              <label class="form-label">Start Date</label>
              <calendar-modal
                label="Date"
                buttonLabel="Apply"
                class="text-secondary"
                (emitValue)="selectDateFilter($event, 'start')"
                (emitDate)="selectDateFilter($event, 'start')"
                placeholder="yyyy/mm/dd"
              ></calendar-modal>
            </div>
            -
            <div class="col-5 text-start pe-0">
              <label class="form-label">End Date</label>
              <calendar-modal
                label="Date"
                buttonLabel="Apply"
                class="text-secondary"
                (emitValue)="selectDateFilter($event, 'end')"
                (emitDate)="selectDateFilter($event, 'end')"
                placeholder="yyyy/mm/dd"
                calendarStyle="right: 0; left: auto;"
              ></calendar-modal>
            </div>
          </div>
          <div class="col-12 text-end">
            <button class="actionBtn" (click)="submitDateFilter()">
              Apply
            </button>
          </div>
        </div>
      </button>
    </div>

    <!-- Tab panes -->
    <div class="tab-content px-0">
      <div
        class="tab-pane"
        [class.active]="currentTab == 'bonus'"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <ng-container *ngFor="let item of tokenHistory">
          <div class="text-center">
            <small class="text-muted">{{
              item.date === "Today"
                ? item.date
                : (item.date | date : "dd MMMM yyyy" : utc_offset)
            }}</small>
          </div>
          <div class="row g-0 mb-5">
            <div
              class="col-12 d-flex align-items-center justify-content-between border-bottom py-2"
              *ngFor="let token of item.tokens"
            >
              <div>
                <p class="mb-0 fw-bold">{{ token.type | titlecase }}</p>
                <small class="text-muted">
                  {{ token.createdAt | date : "HH:mm" : utc_offset }}
                </small>
              </div>
              <p class="mb-0 text-success">
                {{ token.amount | number : "1.2-2" }} EXS
              </p>
            </div>
          </div>
        </ng-container>
        <no-data
          *ngIf="tokenHistory.length == 0"
          message="No token details found"
        ></no-data>
      </div>
      <div
        class="tab-pane"
        [class.active]="currentTab == 'purchased'"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <ng-container *ngFor="let item of tokenHistory">
          <div class="text-center">
            <small class="text-muted">{{
              item.date === "Today"
                ? item.date
                : (item.date | date : "dd MMMM yyyy" : utc_offset)
            }}</small>
          </div>
          <div class="row g-0 mb-5">
            <div
              class="col-12 d-flex align-items-center justify-content-between border-bottom py-2"
              *ngFor="let token of item.tokens"
            >
              <div>
                <p class="mb-0 fw-bold">{{ token.type | titlecase }}</p>
                <small class="text-muted">
                  {{ token.createdAt | date : "HH:mm" : utc_offset }}
                </small>
              </div>
              <p class="mb-0 text-success">
                {{ token.amount | number : "1.2-2" }} EXS
              </p>
            </div>
          </div>
        </ng-container>
        <no-data
          *ngIf="tokenHistory.length == 0"
          message="No token details found"
        ></no-data>

        <no-data
          *ngIf="tokenHistory.length == 0"
          message="No token history found"
        ></no-data>
      </div>
      <div
        class="tab-pane"
        [class.active]="currentTab == 'subscription'"
        id="messages"
        role="tabpanel"
        aria-labelledby="messages-tab"
      >
        <ng-container *ngFor="let item of tokenHistory">
          <div class="text-center">
            <small class="text-muted">{{
              item.date === "Today"
                ? item.date
                : (item.date | date : "dd MMMM yyyy" : utc_offset)
            }}</small>
          </div>
          <div class="row g-0 mb-5">
            <div
              class="col-12 d-flex align-items-center justify-content-between border-bottom py-2"
              *ngFor="let token of item.tokens"
            >
              <div>
                <p class="mb-0 fw-bold">{{ token.type | titlecase }}</p>
                <small class="text-muted">
                  {{ token.createdAt | date : "HH:mm" : utc_offset }}
                </small>
              </div>
              <p class="mb-0 text-success">
                {{ token.amount | number : "1.2-2" }} EXS
              </p>
            </div>
          </div>
        </ng-container>
        <no-data
          *ngIf="tokenHistory.length == 0"
          message="No token details found"
        ></no-data>
      </div>
    </div>
  </div>
</swap-modal>
