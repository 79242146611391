import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TokenBundle } from '../../../services/core/IApp';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent {
  @Input() modalId: string = '';

  @Input() topUpAmount: TokenBundle[] = [];

  @Output() submitTopup: EventEmitter<TokenBundle> =
    new EventEmitter<TokenBundle>();

  tokenBundle: TokenBundle | any = null;

  constructor(public globals: GlobalsService) {}

  onSubmit() {
    this.submitTopup.emit(this.tokenBundle);
  }

  onValueChange(option: TokenBundle): void {
    this.tokenBundle = option;
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== mainElement.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }
    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option-item')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }
}
