import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SocketService } from '../../../services/api/socketio/socket.service';
import { GlobalsService } from '../../../services/core/globals';

@Component({
  selector: 'live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss'],
})
export class LiveChatComponent implements OnInit {
  @Input() ticketCategories: { _id: string; item: string }[] = [];

  openChat: boolean = false;
  openConversation: boolean = false;
  userid: string = '';
  chatForm: FormGroup = new FormGroup({
    message: new FormControl('', Validators.compose([Validators.required])),
  });
  ticketForm: FormGroup = new FormGroup({
    message: new FormControl('', Validators.compose([Validators.required])),
    ticket_category: new FormControl(
      '',
      Validators.compose([Validators.required])
    ),
    subject: new FormControl('', Validators.compose([Validators.required])),
  });

  // ticketCategories: Array<string> = [
  //   'App Security',
  //   'Transfer Delay',
  //   'Onboarding Issues',
  //   'Token Query',
  //   'Payment Upload',
  //   'Uncredited Token',
  // ];

  ticketListener: any;

  constructor(
    public socketService: SocketService,
    public globals: GlobalsService
  ) {}

  ngOnInit() {
    this.userid = this.globals.storage.getUserDetails()?._id;
    if (!this.userid) return;
    this.startListening();
    this.chatForm.patchValue({
      sender: this.userid,
      time: new Date(),
      receiver: 'support',
    });
  }

  startListening() {
    this.ticketListener = this.socketService
      .listenToMessages()
      .subscribe((res) => {
        this.socketService.ticket.messages.push(res);
        this.globals.scrollToBottom('chat-container');
      });
  }

  ngOnDestroy() {
    this.ticketListener?.unsubscribe();
  }

  sendMessage() {
    this.chatForm.value.message = this.chatForm.value.message.trim();
    this.socketService.sendTicketMessage(this.chatForm.value.message);
    this.chatForm.patchValue({
      message: '',
    });
    this.globals.scrollToBottom('chat-container');
  }

  async sendTicket() {
    if (this.ticketForm.invalid)
      return this.globals.toast.warn(
        'Please complete the ticket form to proceed!!'
      );
    this.ticketForm.value.message = this.ticketForm.value.message.trim();
    await this.socketService.createTicket(this.ticketForm.value);
    this.ticketForm.reset();
    this.openConversation = true;
    this.globals.toast.success('Ticket created successfully!!');
  }

  ticketCategoryDropDown() {
    let dropDown = document.querySelector('.dropdown') as HTMLElement;
    dropDown.classList.toggle('active');
    // Close the dropdown if the user clicks outside of it
    document.addEventListener('click', function (e: any) {
      if (!dropDown.contains(e.target)) {
        dropDown.classList.remove('active');
      }
    });
  }

  selectCategory(category: string) {
    this.ticketForm.patchValue({
      ticket_category: category,
    });
  }

  backButton() {
    this.openConversation = false;
    this.socketService.ticket = null;
  }

  isTimeEqualToNow(time: Date) {
    return new Date(time).toDateString() === new Date().toDateString();
  }
}
