import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJs from 'crypto-js';
import { MixpanelService } from '../api/mixpanel/mixpanel.service';
import { Country, Notification, Ratings_Reviews, State, User } from './IApp';
import { NotificationService } from './notification';
import { SpinnerService } from './spinner';
import { StorageService } from './storage';
import { ToasterService } from './toaster';
import { Variables } from './variable';
import { DatePipe } from '@angular/common';
const { USER_EX_ID } = Variables;

@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  user: User | any;
  ratingReview: Ratings_Reviews | any;
  notifications: Notification[] = [];
  unreadMessages: boolean = false;
  numberOfUnreadMessages: number = 0;
  utc_offset: string = '';
  countries: Country[] = [];
  states: State[] = [];
  keywords = {
    offerRequests: [
      'ongoing-bidding',
      'offer-archived',
      'bidding-completed',
      'bidding-offer-declined',
      'bidding-offer-cancelled',
      'bidding-offer-approved',
      'bidding-offer-halted',
      'new-bidding-offer',
      'insufficient-bidding-offer-amount',
    ],
    ratingsReviews: ['ratings-reviews'],
  };
  paymentMethods: { _id: string; item: string }[] = [];
  helpCategories: { _id: string; item: string }[] = [];
  reportCategories: { _id: string; item: string }[] = [
    { _id: '1', item: 'Harmful or illegal' },
    { _id: '2', item: 'Personal Information' },
    { _id: '3', item: 'Not based on a genuine experience' },
    { _id: '4', item: 'Advertising or promotional' },
    { _id: '5', item: 'Inappropriate content' },
  ];
  faqs: { _id: string; question: string; answer: string }[] = [];
  previousModal: string = '';

  constructor(
    public storage: StorageService,
    public router: Router,
    public notification: NotificationService,
    public activatedRoute: ActivatedRoute,
    public toast: ToasterService,
    public spinner: SpinnerService,
    public mixpanel: MixpanelService,
    private datePipe: DatePipe
  ) {
    this.countries = (this.storage.getCountries() as Country[]) || [];
    this.user = (this.storage.getUserDetails() as User) || undefined;
    this.notifications =
      (this.storage.getNotifications() as Notification[]) || [];
    this.utc_offset = this.storage.getUserLocationDetails()?.utc_offset || '';
    this.paymentMethods = this.storage.getPaymentMethods() || [];
    this.helpCategories = this.storage.getHelpCategories() || [];
  }

  isMobileView() {
    return window.innerWidth <= 768;
  }

  generateRatingSVG(ratings: number, userRatings: { id: string; stop: any }[]) {
    var gold = '#EAAC30';
    var rating_ceil = Math.ceil(ratings);
    for (let star_index = 0; star_index <= rating_ceil; star_index++) {
      var linearGrad: { id: string; stop: any } = {
        id: `star${star_index + 1}`,
        stop: [],
      };
      if (ratings - star_index >= 1) {
        linearGrad.stop = [
          { offset: '0%', stopColor: gold },
          { offset: '100%', stopColor: gold },
        ];
      } else if (ratings - star_index >= 0.5) {
        linearGrad.stop = [
          { offset: '0%', stopColor: gold },
          { offset: '50%', stopColor: gold },
          { offset: '50%', stopColor: 'transparent' },
          { offset: '100%', stopColor: 'transparent' },
        ];
      }
      userRatings.push(linearGrad);
    }
  }

  async loggedOut() {
    this.storage.logOutUser();
  }

  async isLoggedIn() {
    return this.storage.isAuthenticated();
  }

  searchCountryBy(search: string | any, type: string): any {
    if (!search) return null;
    switch (type) {
      case 'name':
        return search
          ? this.countries.find(
              (country) =>
                country.name.toLocaleLowerCase() === search.toLocaleLowerCase()
            )
          : null;
      case 'code':
        return this.countries.find(
          (country) =>
            country.currency.toLocaleLowerCase() == search.toLocaleLowerCase()
        );
      default:
        return null;
    }
  }

  calculatePagination(totalNoOfPages: number, page: number) {
    let totalPages = [];
    if (totalNoOfPages <= 5) {
      for (let i = 1; i <= totalNoOfPages; i++) totalPages.push(i);
    }
    if (totalNoOfPages > 5) {
      if (page <= 3) {
        for (let i = 1; i <= 5; i++) totalPages.push(i);
        totalPages.push('...');
        totalPages.push(totalNoOfPages);
      } else if (page >= totalNoOfPages - 2) {
        totalPages.push(1);
        totalPages.push('...');
        for (let i = totalNoOfPages - 4; i <= totalNoOfPages; i++)
          totalPages.push(i);
      } else {
        totalPages.push(1);
        totalPages.push('...');
        for (let i = Number(page); i <= Number(page) + 2; i++)
          totalPages.push(i);
        totalPages.push('...');
        totalPages.push(totalNoOfPages);
      }
    }
    return totalPages;
  }

  closeModal(modalId?: string) {
    if (modalId) {
      let closeBtn = document.querySelector(
        `${modalId} .closeBtn`
      ) as HTMLElement;
      if (closeBtn) closeBtn.click();
    } else {
      // remove modal backdrop
      const element = document.querySelector('.modal-backdrop');
      if (element) element.remove();

      // remove modal open class
      const element2 = document.querySelector('.modal-open');

      if (element2) {
        element2?.classList.remove('modal-open');
        element2?.removeAttribute('style');
      }
    }
  }

  openModal(modalId?: string) {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.setAttribute('style', 'display:none;');
    button.setAttribute('data-bs-toggle', 'modal');
    button.setAttribute('data-bs-target', `#${modalId}`);
    document.body.appendChild(button);
    button.click();
  }

  scrollToBottom(id: string = 'messages') {
    const element = document.getElementById(id) as HTMLElement | any;
    setTimeout(() => {
      element.scrollTop = element?.scrollHeight;
    }, 100);
  }

  replaceCurlies(string: string): string {
    return string
      .replace(/{{/g, '<b class="color-text">')
      .replace(/}}/g, '</b>');
  }

  async checkForReview(ratingReview: Ratings_Reviews) {
    this.ratingReview = ratingReview;
    if (this.ratingReview) {
      setTimeout(() => {
        this.openModal('ratingsReviewModal');
      }, 1000);
    }
  }

  getWindowFocus(): boolean {
    return window.document.hasFocus();
  }

  checkForNotifications(settings: any, notification: Notification | any) {
    Object.keys(settings).forEach((key) => {
      if (!settings[key].push) return;
      if (key == 'offerRequests' || key == 'ratingsReviews') {
        if (this.includesAll(notification.trigger, this.keywords[key])) {
          this.notification.send('toast', notification);
          if (!this.getWindowFocus()) {
            this.notification.send('browser', notification);
          }
        }
      } else if (key == 'messages') {
        if (notification == 'message') {
          if (!this.getWindowFocus()) {
            this.notification.send('sound', 'message');
          }
        }
      }
    });
  }

  private includesAll(trigger: string, keywords: Array<string>) {
    return keywords.filter((keyword) => trigger?.includes(keyword)).length > 0;
  }

  navigateByTrigger(trigger: string, biddingId: string | undefined) {
    switch (trigger) {
      case 'ongoing-bidding':
        this.router.navigateByUrl(
          '/main/transaction/' + biddingId + '/messages'
        );
        break;
      case 'bidding-offer-approved':
        this.router.navigateByUrl(
          '/main/transaction/' + biddingId + '/messages'
        );
        break;
      case 'offer-archived':
        this.router.navigateByUrl('/main/profile?tab=1&offersTab=archived');
        break;
      case 'insufficient-bidding-offer-amount':
        this.router.navigateByUrl('/main/activities');
        break;
      case 'bidding-offer-cancelled':
        this.router.navigate(['/main/activities', biddingId], {
          queryParams: { tab: 'cancelled' },
        });
        break;
      case 'bidding-offer-declined':
        this.router.navigateByUrl(`/main/activities/${biddingId}?tab=declined`);
        break;
      case 'bidding-offer-completed':
        this.router.navigateByUrl(
          `/main/activities/${biddingId}?tab=completed`
        );
        break;
      case 'new-bidding-offer':
        this.router.navigateByUrl('/main/transaction/' + biddingId);
        break;
      case 'verification-tier-upgraded':
        this.router.navigate(['/main/profile'], { queryParams: { tab: 5 } });
        break;
      case 'bidding-offer-halted':
        this.openModal('upgradeModal');
        break;
      case 'ratings-reviews':
        this.router.navigate(['/main/profile'], { queryParams: { tab: 4 } });
        break;
      case 'message':
      default:
        break;
    }
  }

  deactivateUser() {
    this.storage.clearAllExceptRememberMe();
    this.spinner.hide();
    this.router.navigate(['auth']);
    this.openModal('deactivated-user-modal');
  }

  quickEncrypt(exID: string) {
    let encryptedData = '';
    let data: any = {
      exID,
      expiresAt: new Date().getTime() + 600000,
    };
    data = JSON.stringify(data);
    encryptedData = CryptoJs.AES.encrypt(data, USER_EX_ID).toString();
    return encryptedData;
  }

  setPreviousModal(modalId: string) {
    this.previousModal = modalId;
  }

  openPreviousModal() {
    this.openModal(this.previousModal);
  }

  formatDateAndTimestamp(timestamp: string | Date | null): string {
    if (!timestamp) {
    return 'N/A';
    }
    let dateObj: Date;
    
    if (typeof timestamp === 'string') {
      dateObj = new Date(timestamp);
    } else {
      dateObj = timestamp;
    }
    
    if (isNaN(dateObj.getTime())) {
      return 'Invalid Date';
    }
    
    const localTime = this.datePipe.transform(dateObj, 'MMM d, y HH:mm:ss');
    const gmtOffset = dateObj.getTimezoneOffset();  
    const sign = gmtOffset <= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(gmtOffset / 60)).toString().padStart(2, '0');
    const minutes = Math.abs(gmtOffset % 60).toString().padStart(2, '0');
    const gmtOffsetFormatted =  `GMT${sign}${hours}:${minutes}`;
    
    return localTime + ' ' + gmtOffsetFormatted;
  
}


formatTimestamp(timestamp: string | Date | null): string {
  if (!timestamp) {
    return 'N/A';
  }

  let dateObj: Date;
  
  if (typeof timestamp === 'string') {
    dateObj = new Date(timestamp);
  } else {
    dateObj = timestamp;
  }

  if (isNaN(dateObj.getTime())) {
    return 'Invalid Date';
  }
  const localTime = this.datePipe.transform(dateObj, 'HH:mm:ss');
  const gmtOffset = dateObj.getTimezoneOffset();
  const sign = gmtOffset <= 0 ? '+' : '-';
  const hours = Math.abs(Math.floor(gmtOffset / 60)).toString().padStart(2, '0');
  const minutes = Math.abs(gmtOffset % 60).toString().padStart(2, '0');
  const gmtOffsetFormatted = `GMT${sign}${hours}:${minutes}`;

  return localTime + ' ' + gmtOffsetFormatted;
}
}
