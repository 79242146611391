<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-bottom pb-4 mx-3">
    <h2 class="modal-title fs-6 fw-bolder" id="staticBackdropLabel">Topup</h2>
    <button
      type="button"
      class="btn-close closeBtn"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeModal
    ></button>
  </div>
  <div class="modal-body m-3 mt-5 pt-0">
    <form (ngSubmit)="onSubmit()">
      <div class="card pink border-0 mb-5">
        <img src="assets/images/token-card-pattern-pink.svg" alt="" />
        <div class="position-relative z-1">
          <p class="mb-0 text-white">Balance</p>
          <h4 class="text-white fw-bolder">
            {{
              globals.user?.tokens?.balance?.bonus +
                globals.user?.tokens?.balance?.purchased +
                globals.user?.tokens?.balance?.subscription | number : "1.0-0"
            }}
            <span class="fw-light">EXS</span>
          </h4>
        </div>
      </div>

      <div class="mb-3">
        <label class="mb-2 input-label">Select amount to buy</label>
        <button class="form-input dropdown" (click)="selectDropdown($event)">
          <p class="mb-0 truncate text-muted">
            {{
              tokenBundle
                ? (tokenBundle?.tokens
                    | number : "1.0-0") +
                  "EXS for $" +
                  (tokenBundle?.amount
                    | number : "1.2-2")
                : "Select Amount"
            }}
          </p>
          <div class="dropdown-options" id="countryOptions">
            <div
              class="dropdown-option-item"
              *ngFor="let option of topUpAmount"
              (click)="onValueChange(option)"
            >
              {{ option.tokens | number : "1.0-0" }}EXS for ${{
                option.amount | number : "1.2-2"
              }}
            </div>
          </div>
        </button>
      </div>

      <div class="d-flex align-items-center font-12 mb-3 gap-2 validity">
        <p class="mb-0 fw-bold">Validity Type:</p>
        <p class="mb-0 text-muted">Does not expire.</p>
      </div>

      <button type="submit" class="createBtn" [disabled]="!tokenBundle">
        Buy Token
      </button>
    </form>
  </div>
</swap-modal>
