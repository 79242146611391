import { Component, Input } from '@angular/core';

@Component({
  selector: 'confirm-change-plan',
  templateUrl: './confirm-change-plan.component.html',
  styleUrls: ['./confirm-change-plan.component.scss'],
})
export class ConfirmChangePlanComponent {
  @Input() modalId: string = '';

  @Input() plan: string = '';

  @Input() period: string = '';
}
