import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Token } from 'src/app/services/core/IApp';

@Component({
  selector: 'token-details',
  templateUrl: './token-details.component.html',
  styleUrls: ['./token-details.component.scss'],
})
export class TokenDetailsComponent {
  @Input() modalId: string = '';

  @Input() currentTab: string = 'spent';

  @Input() utc_offset: string = '';

  @Input() tokenHistory: any[] = [];

  @Output() currentTabChange: EventEmitter<string> = new EventEmitter<string>();

  tabs: string[] = ['spent', 'earned', 'purchased'];

  @Output() filterChange: EventEmitter<{ startDate: string; endDate: string }> =
    new EventEmitter<{ startDate: string; endDate: string }>();

  filterOptions: Array<{ name: string; value: string }> = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'This week', value: 'this_week' },
    { name: 'This month', value: 'this_month' },
    { name: 'Previous month', value: 'previous_month' },
  ];

  startDate: string = '';
  endDate: string = '';

  constructor() {}

  ngOnChanges() {
    this.tokenHistory = this.groupItemsByDate(this.tokenHistory);
  }

  groupItemsByDate(items: any[]) {
    const groupedItems: Token | any = {};

    items.forEach((item) => {
      // Extract the date part (ignoring the time)
      const date = new Date(item?.createdAt || Date.now())
        .toISOString()
        .split('T')[0];

      if (!groupedItems[date]) {
        groupedItems[date] = [];
      }

      groupedItems[date].push(item);
    });

    const result = [];

    for (const date in groupedItems) {
      if (groupedItems.hasOwnProperty(date)) {
        result.push({
          date:
            date === new Date().toISOString().split('T')[0] ? 'Today' : date,
          tokens: groupedItems[date],
        });
      }
    }

    return result;
  }

  selectDropdown(event: any) {
    // close other dropdown options
    event.preventDefault();
    let mainElement = event.target;
    const element = document.querySelectorAll('.dropdown-options');
    element.forEach((el) => {
      if (
        el.classList.contains('show') &&
        el !== event.target.nextElementSibling
      ) {
        el.classList.remove('show');
      }
    });

    if (mainElement.nodeName !== 'BUTTON') mainElement = mainElement.parentNode;

    // add show class to dropdown options
    for (let i = 0; i < mainElement.children.length; i++) {
      if (mainElement.children[i].classList.contains('dropdown-options')) {
        mainElement.children[i].classList.toggle('show');
      }
    }

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (!e.target?.classList.contains('dropdown-option')) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  selectFilter(filter: { name: string; value: string }) {
    const now = new Date();
    let startDate = '';
    let endDate = '';

    switch (filter.value) {
      case 'today':
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
        break;
      case 'yesterday':
        now.setDate(now.getDate() - 1);
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
        break;
      case 'this_week':
        now.setDate(now.getDate() - now.getDay());
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setDate(now.getDate() + 6)).toISOString();
        break;
      case 'this_month':
        now.setDate(1);
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setMonth(now.getMonth() + 1)).toISOString();
        break;
      case 'previous_month':
        now.setDate(1);
        now.setMonth(now.getMonth() - 1);
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setMonth(now.getMonth() + 1)).toISOString();
        break;
    }
    this.filterChange.emit({ startDate, endDate });
  }

  selectDateFilter(dateEvent: string, type: string) {
    switch (type) {
      case 'start':
        const date = new Date(dateEvent);
        this.startDate = new Date(date.setHours(0, 0, 0, 0)).toISOString();
        break;
      case 'end':
        const date2 = new Date(dateEvent);
        this.endDate = new Date(date2.setHours(23, 59, 59, 999)).toISOString();
        break;
    }
  }

  triggerCalendar() {
    // close other dropdown options
    const element = document.querySelectorAll('.calendar-dropdown');
    element.forEach((el) => {
      if (el.classList.contains('show')) {
        el.classList.remove('show');
      }
    });

    // add show class to dropdown options
    element.forEach((el) => {
      el.classList.toggle('show');
    });

    // close dropdown options when click outside
    document.addEventListener('mousedown', (e: any) => {
      if (
        !e.target?.classList.contains('date-input') &&
        !e.target?.classList.contains('calendar-dropdown') &&
        !e.target?.classList.contains('actionBtn') &&
        !e.target?.classList.contains('calendar_day_item')
      ) {
        element.forEach((el) => {
          if (el.classList.contains('show')) {
            el.classList.remove('show');
          }
        });
      }
    });
  }

  submitDateFilter() {
    this.filterChange.emit({ startDate: this.startDate, endDate: this.endDate });
    const element = document.querySelectorAll('.calendar-dropdown');
    element.forEach((el) => {
      if (el.classList.contains('show')) {
        el.classList.remove('show');
      }
    });
  }
}
