<swap-modal [modalId]="modalId" [centered]="true">
  <div class="row m-3">
    <div class="col-12 mb-2">
      <img
        loading="lazy"
        src="assets/icons/warning-with-inner-circle.svg"
        alt=""
      />
    </div>
    <div class="col-12 d-flex mb-3">
      <h5 class="modal-title mb-0 fw-bolder" id="exampleModalLabel">
        Report Transaction
      </h5>
      <button
        type="button"
        class="closeBtn d-none"
        data-bs-dismiss="modal"
        aria-label="Close"
        #closeBtn
      >
        <img loading="lazy" src="assets/icons/close-icon.svg" alt="" />
      </button>
    </div>
    <div class="col-12 mb-3">
      <p>
        I request that a quick investigation be conducted as i already sent and
        provided a proof of payment but did not get a response from the other
        party.
      </p>
      <div class="d-flex align-items-center gap-2 mt-4">
        <button
          (click)="emitReportConfirmation.emit(false); closeBtn.click()"
          class="submit-btn fw-700 font-13 btn-decline"
        >
          Cancel
        </button>
        <button
          class="submit-btn fw-700 font-13"
          (click)="emitReportConfirmation.emit(true); closeBtn.click()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</swap-modal>
