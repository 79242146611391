<div class="position-relative">
  <div class="d-flex align-items-center">
    <input
      class="date-input {{ class }}"
      id="{{ label }}"
      type="text"
      name="{{ label }}"
      placeholder="{{ placeholder }}"
      [value]="value"
      (focus)="showCalendar = true; closeCalendar()"
      [ngStyle]="style"
      readonly
      required
    />
  </div>
  <div
    class="calendar row gy-3"
    [style]="calendarStyle"
    [class.d-block]="showCalendar === true"
    [class.d-none]="showCalendar === false"
  >
    <div
      class="col-12 calendar_header d-flex justify-content-between gap-2 px-4"
    >
      <i class="bi bi-chevron-left pointer" (click)="prevMonth()"></i>
      {{ months[currentMonth] + " " + (currentYear + 1) }}
      <i class="bi bi-chevron-right pointer" (click)="nextMonth()"></i>
    </div>
    <div class="col-12 calendar_body p-2 px-4">
      <ul class="calendar_header">
        <li class="calendar_header_item">Mon</li>
        <li class="calendar_header_item">Tue</li>
        <li class="calendar_header_item">Wed</li>
        <li class="calendar_header_item">Thu</li>
        <li class="calendar_header_item">Fri</li>
        <li class="calendar_header_item">Sat</li>
        <li class="calendar_header_item">Sun</li>
      </ul>
      <div class="calendar_days">
        <ul class="calendar_day">
          <li
            class="calendar_day_item"
            *ngFor="let day of days"
            [class.inactive]="day.disabled"
            [class.today]="day.today"
            (click)="setDate(day.date)"
          >
            {{ day.date }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 text-center p-2 mb-2 border-top">
      <button type="button" class="actionBtn cancel" (click)="cancelDate()">
        Cancel
      </button>
      <button type="button" class="actionBtn" (click)="submitDate()">
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
