<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title font-18 fw-bolder">
      Add {{ selectedCurrency.code }} Bank Account
    </h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="
        success = false; addBankForm.reset(); globals.openPreviousModal()
      "
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form
      [formGroup]="addBankForm"
      class="row g-3"
      (ngSubmit)="onSubmit()"
      *ngIf="!success"
    >
      <p class="mb-2 font-14 text-secondary fw-700">Account Details</p>
      <div class="col-12 mt-4">
        <label class="form-label fw-700 font-14"
          >Bank Name
          <span class="text-danger">*</span>
        </label>
        <button
          class="input-shape dropdown"
          (click)="toggleDropdown($event)"
          [ngClass]="{ 'text-muted': !addBankForm.value.bankName }"
        >
          <input
            type="text"
            placeholder="Search for bank..."
            class="w-100 d-none font-14"
            (input)="onBankNameChange($event)"
          />
          <p
            class="truncate mb-0 font-14"
            [ngStyle]="{
              width: addBankForm.value.bankName ? '18rem' : 'auto'
            }"
          >
            {{ addBankForm.value.bankName || "Select Bank" }}
          </p>

          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="let bank of transactionService.banks"
              (click)="setBank(bank)"
            >
              {{ bank?.attributes?.name || bank?.name }}
            </div>
          </div>
        </button>
        <!-- <div
          class="input-shape d-flex align-items-center p-3"
          *ngIf="
            selectedCurrency.code !== 'NGN' && selectedCurrency.code !== 'EUR'
          "
        >
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="bankName"
            id="bankName"
            formControlName="bankName"
            autocomplete="off"
            placeholder="Bank Name"
          />
        </div> -->
      </div>

      <div class="col-12 mt-4">
        <p class="fw-700 mb-2 font-14">
          {{
            selectedCurrency.code !== "NGN"
              ? "IBAN/ Account Number"
              : "Account Number"
          }}<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="tel"
            pattern="[0-9]*"
            name="accountNumber"
            id="accountNumber"
            formControlName="accountNumber"
            autocomplete="off"
            placeholder="Account Number"
            (input)="onAccountNumberChange($event)"
          />
        </div>
      </div>

      <div class="col-12 mt-4">
        <p class="fw-700 mb-2 font-14">
          {{
            selectedCurrency.code === "EUR" || selectedCurrency.code === "GBP"
              ? "Full Name of Account Holder"
              : "Account Name"
          }}
          <span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="accountName"
            id="accountName"
            formControlName="accountName"
            autocomplete="off"
            placeholder="Full Name"
          />
        </div>
      </div>

      <div class="col-12 mt-4" *ngIf="selectedCurrency.code === 'USD'">
        <p class="fw-700 mb-2 font-14">
          ACH routing Number<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="achRoutingNumber"
            id="achRoutingNumber"
            formControlName="achRoutingNumber"
            autocomplete="off"
            placeholder="Full Name"
          />
        </div>
      </div>

      <div
        class="col-12 mt-4"
        *ngIf="
          selectedCurrency.code === 'EUR' || selectedCurrency.code === 'AED'
        "
      >
        <p class="fw-700 mb-2 font-14">
          SWIFT/BIC Code<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="swiftCode"
            id="swiftCode"
            formControlName="swiftCode"
            autocomplete="off"
            placeholder="SWIFT/BIC Code"
          />
        </div>
      </div>

      <div class="col-12 mt-4" *ngIf="selectedCurrency.code === 'CAD'">
        <p class="fw-700 mb-2 font-14">
          Institution Number<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="institutionNumber"
            id="institutionNumber"
            formControlName="institutionNumber"
            autocomplete="off"
            placeholder="Institution Number"
          />
        </div>
      </div>

      <div class="col-12 mt-4" *ngIf="selectedCurrency.code === 'CAD'">
        <p class="fw-700 mb-2 font-14">
          Transit Number<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="transitNumber"
            id="transitNumber"
            formControlName="transitNumber"
            autocomplete="off"
            placeholder="Transit Number"
          />
        </div>
      </div>

      <div
        class="col-12 mt-4"
        *ngIf="
          selectedCurrency.code === 'CAD' || selectedCurrency.code === 'USD'
        "
      >
        <p class="fw-700 mb-2 font-14">
          Account Type<span class="text-danger">*</span>
        </p>
        <button
          class="input-shape dropdown"
          (click)="toggleDropdown($event)"
          [ngClass]="{ 'text-muted': !addBankForm.value.accountType }"
        >
          <input
            type="text"
            placeholder="Type..."
            class="w-100 d-none font-14"
            [(ngModel)]="searchTerm"
            [ngModelOptions]="{ standalone: true }"
          />
          <p
            class="truncate mb-0 font-14"
            [ngStyle]="{
              width: addBankForm.value.accountType ? '18rem' : 'auto'
            }"
          >
            {{ addBankForm.value.accountType || "Select Account Type" }}
          </p>

          <div class="dropdown-options">
            <div
              class="dropdown-option"
              *ngFor="
                let type of accountTypes
                  | searchFilter : searchTerm : ['attributes.name']
              "
              (click)="setAccountType(type); searchTerm = ''"
            >
              {{ type }}
            </div>
          </div>
        </button>
      </div>

      <div class="col-12 mt-4" *ngIf="selectedCurrency.code === 'GBP'">
        <p class="fw-700 mb-2 font-14">
          UK Sort Code<span class="text-danger">*</span>
        </p>
        <div class="input-shape d-flex align-items-center p-3">
          <input
            class="font-14 me-2 amount-input w-100"
            type="text"
            name="sortCode"
            id="sortCode"
            formControlName="sortCode"
            autocomplete="off"
            placeholder="Sort Code"
          />
        </div>
      </div>

      <div class="col-12 mt-4">
        <button type="submit" class="createBtn" [disabled]="!addBankForm.valid">
          Add Bank
        </button>
      </div>

      <!-- Success Message -->
      <!-- <div *ngIf="success">
        <div class="align-items-center justify-content-center text-center" style="margin-top: 10rem;">
          <div>
            <img src="assets/images/Illustration.png" alt="Create Wallet" class="modal-image">
            <h4>Wallet Created</h4>
            <p [innerHtml]="message"></p>
            <p [innerHtml]="successMessage"></p>
          </div>
          <div>
            <p class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700" style="margin-top: 3rem; width: 100%;">Fund Wallet</p>
          </div>
        </div>
      </div> -->
    </form>

    <!-- Success Message -->
    <div *ngIf="success">
      <div
        class="align-items-center justify-content-center text-center"
        style="margin-top: 8rem"
      >
        <div>
          <img
            src="assets/images/Illustration.png"
            alt="Create Wallet"
            class="modal-image"
          />
          <h4 class="font-18 fw-700 mt-2">
            Bank Account Added <br />
            Successfully
          </h4>
        </div>
      </div>
    </div>
  </div>
</swap-modal>
