<swap-modal
  [modalId]="modalId"
  [scrollable]="true"
  [right]="true"
  backdrop="static"
  [keyboard]="false"
>
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title fs-5 fw-bolder">Fund Wallet</h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="emptyVariables.emit(true); completed = false"
    ></button>
  </div>
  <div class="modal-body mx-3">
    <ng-container *ngIf="completed == false"
      ><div class="col-12 mt-4">
        <div class="bank-details rounded-2">
          <div class="col-12 mb-2">
            <p class="mb-2 font-14">Total Transfer Amount</p>
            <h6 class="fw-700 mb-4">
              {{ walletTransaction?.currency?.code }}
              {{
                walletTransaction
                  ? (walletTransaction.amount + 0.05 * walletTransaction.amount
                    | number : "1.2-2")
                  : (0 | number : "1.2-2")
              }}
            </h6>
          </div>
          <div class="col-12 mb-2">
            <p class="mb-2 font-14">
              {{ displayEscrowFees ? "Escrow Fees" : "Top Up Fees" }}
            </p>
            <h6 class="fw-700 mb-4">
              NGN
              {{ 0.05 * (walletTransaction?.amount || 0) | number : "1.2-2" }}
            </h6>
          </div>
          <div class="col-12 mb-2">
            <p class="mb-2 font-14">Offer Amount</p>
            <h6 class="fw-700 mb-4">
              {{ walletTransaction?.currency?.code }}
              {{ walletTransaction?.amount || 0 | number : "1.2-2" }}
            </h6>
          </div>
          <div class="col-12 mb-2">
            <p class="mb-2 font-14">Purpose</p>
            <h6 class="fw-700 mb-2">
              {{ walletTransaction?.purpose | titlecase }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <p class="fw-700 mb-2 font-14 text-center">Transaction Instructions</p>
        <ol class="danger-label mb-5 text-danger font-12 p-2 ps-4 py-3">
          <li>
            Please make a bank transfer of the total amount of
            <span class="fw-700 text-primary"
              >{{ walletTransaction?.currency?.code }}
              {{
                walletTransaction
                  ? (walletTransaction.amount + 0.05 * walletTransaction.amount
                    | number : "1.2-2")
                  : (0 | number : "1.2-2")
              }}</span
            >
            stated above to the bank details provided below.
          </li>
          <li>
            Please transfer the payment from your own account to the account
            number provided below, ensuring that the account number and
            reference are entered accurately.
          </li>
          <li>
            That we are not liable for any errors in the transfer process.
          </li>
        </ol>
        <div class="bank-details text-center rounded-2">
          <p class="mb-2 font-14">Bank Name</p>
          <h6 class="fw-700 mb-4">{{ wallet?.bankName }}</h6>
          <p class="mb-2 font-14">Account Name</p>
          <h6 class="fw-700 mb-4">{{ wallet?.accountName }}</h6>
          <p class="mb-2 font-14">Bank Account Number</p>
          <h6 class="fw-700 mb-4">{{ wallet?.accountNumber }}</h6>
          <p class="mb-2 font-14">Transaction Reference</p>
          <h6 class="fw-700 mb-2">{{ walletTransaction?.tranx_ref }}</h6>
        </div>
      </div>
      <div class="col-12 mt-4">
        <button
          class="createBtn"
          type="button"
          (click)="emptyVariables.emit(true); completed = true"
        >
          Transfer Completed
        </button>
      </div></ng-container
    >
    <div *ngIf="completed == true">
      <div
        class="align-items-center justify-content-center text-center"
        style="margin-top: 8rem"
      >
        <div>
          <img
            src="assets/images/Illustration.png"
            alt="Create Wallet"
            class="modal-image"
          />
          <h4 class="font-18 fw-700 mt-2">Top Up Successful</h4>
          <p class="mb-0 font-14">Your Wallet has been top up successfully.</p>
        </div>
        <div>
          <p
            class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700"
            data-bs-toggle="modal"
            data-bs-target="#fundWallet"
            (click)="closeBtn.click(); completed = false"
            style="margin-top: 3rem; width: 100%; cursor: pointer"
          >
            Return to Wallet
          </p>
        </div>
      </div>
    </div>
  </div>
</swap-modal>
