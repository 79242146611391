<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title fs-5 fw-bolder">Top Up</h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="resetParams()"
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form [formGroup]="topupWalletForm" class="row g-3" (ngSubmit)="onSubmit()">
      <div class="col-12 mt-4">
        <label class="fw-700 mb-2 font-14" for="amount">
          Amount <span class="text-danger">*</span>
        </label>
        <div class="input-shape d-flex align-items-center p-3 gap-2" [class.disabled]="offerAmount">
          <input
            class="font-14 me-2 w-100"
            name="amount"
            id="amount"
            placeholder="0.00"
            type="text"
            [value]="amount.value"
            pattern="^[0-9,]*$"
            [disabled]="offerAmount"
            (input)="formatAmount($event, 'amount')"
          />
          <p class="mb-0 font-14">{{ currency?.code }}</p>
        </div>
        <small
          class="text-danger font-12"
          *ngIf="amount?.invalid && (amount?.dirty || amount?.touched)"
        >
          {{ amount.errors?.['required'] ? 'Amount is required' : 'Amount must be greater than 0' }}
        </small>
      </div>

      <div class="col-12">
        <p class="color-header fw-bold mb-2 font-14">Purpose of Transaction</p>
        <div class="input-shape p-3">
          <div
            class="purpose-dropdown"
            (click)="startDropDown('purpose-dropdown')"
          >
            <span class="font-14">{{
              topupWalletForm.value.purpose || "Choose Purpose"
            }}</span>
            <div class="dropdown-option">
              <div
                class="dropdown-option-item"
                *ngFor="let purpose of transactionPurposeList"
                (click)="selectPurpose(purpose)"
              >
                {{ purpose }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <button
          class="createBtn"
          type="submit"
          [disabled]="!topupWalletForm.valid"
          data-bs-dismiss="modal"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</swap-modal>
