import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'go-back-action',
  templateUrl: './go-back-action.component.html',
  styleUrls: ['./go-back-action.component.scss'],
})
export class GoBackActionComponent {
  constructor(private location: Location, private globals: GlobalsService) {}
  @Input() disableGoBack: boolean = false;
  @Input() text: string = 'Go Back';
  @Input() route: string = '';
  @Input() queryParams: string = '';

  goBack() {
    if (!this.disableGoBack) return this.location.back();
    this.globals.router.navigateByUrl(this.route);
  }
}
