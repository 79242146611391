<div class="position-relative">
  <button
    class="roundBtn"
    type="button"
    [ngStyle]="{
      transform: openChat ? 'scale(1)' : 'scale(0)'
    }"
    (click)="openChat = !openChat"
  >
    <i class="bi bi-x font-30"></i>
  </button>

  <button
    class="roundBtn"
    type="button"
    [ngStyle]="{
      transform: !openChat ? 'scale(1)' : 'scale(0)'
    }"
    (click)="openChat = !openChat"
  >
    <i class="bi bi-chat-right-text-fill"></i>
  </button>
  <div
    class="d-flex flex-column chat-container"
    [ngStyle]="{
      transform: openChat ? 'translateY(0)' : 'translateY(150%)'
    }"
  >
    <div class="col-12 chat-header">
      <div class="row gy-5 mx-1">
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button
            class="backBtn"
            (click)="backButton()"
            *ngIf="socketService.ticket?.messages.length > 0"
          >
            <i class="bi bi-chevron-left"></i>
          </button>
        </div>
        <div class="col-2">
          <div class="logo">
            <img loading="lazy" src="assets/images/logoIcon.svg" />
          </div>
        </div>
        <div class="col-8 d-flex align-items-center">
          <p class="text-center font-16 fw-600 m-0 ps-2 truncate">
            Expatswap Support
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center h-100"
      *ngIf="!userid"
    >
      <p class="text-center font-16 fw-600 m-0">
        You need to login to chat with support
      </p>
    </div>
    <form
      class="d-flex align-items-center flex-column my-4 w-100 h-100 ticketForm"
      [formGroup]="ticketForm"
      [ngStyle]="{
        transform: !openConversation ? 'translateX(0)' : 'translateX(100%)'
      }"
      *ngIf="userid && !openConversation"
      (ngSubmit)="sendTicket()"
    >
      <div class="mb-3 d-flex flex-column w-75">
        <label for="" class="form-label">Subject</label>
        <input
          type="text"
          class="form-input"
          name="subject"
          placeholder="Enter Subject"
          formControlName="subject"
        />
      </div>
      <div class="mb-3 d-flex flex-column w-75">
        <label for="" class="form-label">Ticket Category</label>
        <div class="form-input dropdown" (click)="ticketCategoryDropDown()">
          {{ ticketForm.value.ticket_category || "Select Category" }}
          <div class="dropdown-option">
            <div
              class="dropdown-option-item"
              *ngFor="let category of ticketCategories"
              (click)="selectCategory(category.item)"
            >
              {{ category }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 d-flex flex-column w-75">
        <label for="" class="form-label">Message</label>
        <textarea
          class="form-input"
          name="message"
          id=""
          placeholder="Enter Message"
          rows="7"
          formControlName="message"
        ></textarea>
      </div>
      <button class="submitBtn w-75 py-3" type="submit">Submit</button>
    </form>
    <div
      class="d-flex flex-column chat-holder"
      [ngStyle]="{
        transform: openConversation ? 'translateX(0)' : 'translateX(-150%)'
      }"
      *ngIf="userid && openConversation"
    >
      <div class="col-12 chat-body" id="chat-container">
        <ng-container *ngFor="let chat of socketService.ticket?.messages">
          <div
            class="col-12 d-flex justify-content-start my-3 expatChat"
            *ngIf="chat?.authorId != userid"
          >
            <div class="profileLogo">
              <img loading="lazy" src="assets/images/logoIcon.svg" />
            </div>
            <div class="messages">
              <p
                class="mb-0 mt-2"
                *ngIf="!isTimeEqualToNow(chat?.createdAt)"
                [innerHTML]="chat?.content"
              ></p>
              <p
                class="mb-0 mt-2"
                *ngIf="isTimeEqualToNow(chat?.createdAt)"
                [innerHTML]="chat?.content"
              ></p>
            </div>
          </div>
          <div
            class="col-12 d-flex justify-content-end my-3 sender"
            *ngIf="chat.authorId == userid"
          >
            <div class="messages">
              <p
                class="mb-0 mt-2"
                *ngIf="!isTimeEqualToNow(chat?.createdAt)"
                [innerHTML]="chat?.content"
              ></p>
              <p
                class="mb-0 mt-2"
                *ngIf="isTimeEqualToNow(chat?.createdAt)"
                [innerHTML]="chat?.content"
              ></p>
            </div>
            <div class="profileLogo m-0">
              <img loading="lazy" [src]="globals.user?.avatar" />
            </div>
          </div>
        </ng-container>
        <div
          class="col-12 d-flex justify-content-start my-3 expatChat"
          *ngIf="socketService.typing"
        >
          <div class="profileLogo">
            <img loading="lazy" src="assets/images/logoIcon.svg" />
          </div>
          <div class="messages">
            <div class="dot-pulse m-3 mt-4"></div>
          </div>
        </div>
      </div>
      <div class="col-12 chat-footer">
        <form
          class="d-flex align-items-center mx-3"
          [formGroup]="chatForm"
          (ngSubmit)="sendMessage()"
        >
          <input
            type="text"
            formControlName="message"
            class="form-input"
            placeholder="Type a message"
          />
          <button class="sendBtn" type="submit">
            <i class="bi bi-send-fill"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
