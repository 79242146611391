export class Variables {
  public static ACCESS_TOKEN = '%Exp@t_$w@p_Token%';
  public static REG_DETAIL = '%Exp@t_$w@p_USER&REG%';
  public static USER_DETAIL = '%Exp@t_$w@p_USER&DET%';
  public static HOME_PAGE_CURRENCY_SEARCH = '%Exp@t_$w@p_Cur#$E@rch%';
  public static SIGNIN_CREDENTIALS = '%Exp@t_$w@p_SIGNIN&CREDS%';
  public static LOCATION_DETAILS = '%Exp@t_$w@p_LOCATION&DETAILS%';
  public static SIDE_BAR = '%Exp@t_$w@p_SIDE&BAR%';
  public static OUT_OF_TIME = '%Exp@t_$w@p_OUT&OF&TIME%';
  public static OTP_VERIFICATION_ID = '%Exp@t_$w@p_OTP&ID%';
  public static NOTIFICATION = '%Exp@t_$w@p_NOTIFICATION%';
  public static COUNTRY = '%Exp@t_$w@p_COUNTRY%';
  public static HELP_CATEGORIES = '%Exp@t_$w@p_HELP&CATEGORIES%';
  public static PAYMENT_METHODS = '%Exp@t_$w@p_PAYMENT&METHODS%';
  public static FAQS = '%Exp@t_$w@p_FAQS%';
  public static USER_EX_ID = '%Exp@t_$w@p_USER&EX&ID%';
}

export interface Enums {
  TRANSACTION_STATUS: ['ongoing', 'pending', 'completed', 'archived', 'failed'];
  VERIFICATION_STATUS: ['pending', 'verified', 'rejected'];
  WALLET_STATUS: ['active', 'inactive'];
  OFFER_TYPE: ['escrow', 'non-escrow'];
  WALLET_TRANSACTION_STATUS: ['pending', 'completed', 'cancelled'];
}
