<div class="search" [ngStyle]="{
    'background-color': backgroundColor,
    'border-color': borderColor,
    padding: padding
  }">
  <label for="search">
    <img loading="lazy" class="search-icon" src="assets/images/Search.svg" for="search" />
  </label>
  <input class="search-input" type="text" [placeholder]="placeholder" id="search" name="search" [value]="value"
    (input)="emitSearch($event)" />
</div>