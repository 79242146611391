import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'report-transaction',
  templateUrl: './report-transaction.component.html',
  styleUrls: ['./report-transaction.component.scss'],
})
export class ReportTransactionComponent implements OnInit {
  @Input() modalId: string = '';
  @Output() emitReportConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
