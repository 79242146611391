import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'expat-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input() placeholder: string = '';
  @Input() backgroundColor: string = '';
  @Input() borderColor: string = '';
  @Input() padding = '1rem';
  @Input() value: string = '';
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  timeout: any;

  emitSearch(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.search.emit(event.target.value);
    }, 500);
  }
}
