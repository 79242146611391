<swap-modal [modalId]="modalId" [scrollable]="true" [right]="true">
  <div class="modal-header border-0 mx-3 mt-2">
    <h2 class="modal-title font-18 fw-bolder">Create Wallet</h2>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      #closeBtn
      (click)="success = false; step = 1; walletForm.reset()"
    ></button>
  </div>
  <div class="modal-body mx-3">
    <form (ngSubmit)="onSubmit()" [formGroup]="walletForm" class="row g-3">
      <!-- Step 1 Content -->
      <div *ngIf="step === 1 && !success">
        <div class="text-center" style="margin-top: 10rem">
          <img
            src="assets/images/Object1.png"
            alt="Create Wallet"
            width="300"
          />
          <p class="mb-0 font-14 fw-400 mt-3">
            Create your digital wallet in seconds and take control of your
            finances. Simple. Safe. Smart.
          </p>
        </div>
        <button
          class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700"
          (click)="onNext()"
          style="margin-top: 3rem"
        >
          Create Wallet
        </button>
      </div>

      <!-- Step 2 Content -->
      <div *ngIf="step === 2 && !success">
        <p class="mb-2 font-14 text-secondary fw-700">Personal Details</p>
        <div class="col-12 mt-4">
          <p class="color-header fw-700 mb-2 font-14">
            Bvn <span class="text-danger">*</span>
          </p>
          <div class="input-shape p-3">
            <input
              formControlName="bvn"
              placeholder="Enter Bvn"
              class="font-14 w-100 color-text"
              type="tel"
              autocomplete="off"
              pattern="^[0-9,]*$"
              maxlength="11"
              name="bvn"
              id="bvn"
            />
          </div>
        </div>
        <div class="col-12 mt-4">
          <p class="color-header fw-700 mb-2 font-14">
            Address <span class="text-danger">*</span>
          </p>
          <div class="input-shape p-3">
            <input
              formControlName="addressLine_1"
              placeholder="Enter Address"
              class="font-14 w-100 color-text"
              type="text"
              autocomplete="off"
              name="addressLine_1"
              id="addressLine_1"
            />
          </div>
        </div>
        <div class="row g-0 gx-2 mt-4">
          <div class="col-6">
            <label class="form-label fw-700 font-14"
              >Country
              <span class="text-danger">*</span>
            </label>
            <button
              class="input-shape dropdown d-flex align-items-center"
              (click)="selectDropdown($event)"
              [ngClass]="{ 'text-muted': !walletForm.value.country }"
            >
              <p
                class="truncate mb-0 font-14"
                [ngStyle]="{ 
              width: walletForm.value.country ? '4.5em' : 'auto',
             }"
              >
                {{ walletForm.value.country || "Select Country" }}
              </p>
              <img
                loading="lazy"
                [hidden]="
                  !globals.searchCountryBy(walletForm.value.country, 'name')
                "
                [src]="
                  globals.searchCountryBy(walletForm.value.country, 'name')
                    ?.flag
                "
                style="pointer-events: none;"
                alt="country"
              />
              <div class="dropdown-options">
                <div
                  class="dropdown-option"
                  *ngFor="let country of countries"
                  (click)="setCountry(country)"
                >
                  {{ country.name }}
                  <img
                    loading="lazy"
                    [src]="country.flag"
                    alt="country"
                    class="me-2"
                  />
                </div>
              </div>
            </button>
          </div>
          <div class="col-6">
            <label class="form-label fw-700 font-14"
              >State
              <span class="text-danger">*</span>
            </label>
            <button
              class="input-shape dropdown"
              (click)="selectDropdown($event)"
              [ngClass]="{ 'text-muted': !walletForm.value.state }"
            >
              <p
                class="truncate mb-0 font-14"
                [ngStyle]="{ 
              width:  walletForm.value.state ? '4.5em' : 'auto',
             }"
              >
                {{ walletForm.value.state || "Select State" }}
              </p>
              <div class="dropdown-options">
                <div
                  class="dropdown-option"
                  *ngFor="let state of states"
                  (click)="setState(state)"
                >
                  {{ state.name }}
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="col-12 mt-4">
          <label class="form-label fw-700 font-14">City</label>
          <button
            class="input-shape dropdown"
            (click)="selectDropdown($event)"
            [ngClass]="{ 'text-muted': !walletForm.value.city }"
          >
            <p
              class="truncate mb-0 font-14"
              [ngStyle]="{ 
              width:  walletForm.value.city ? '4.5em' : 'auto',
             }"
            >
              {{ walletForm.value.city || "Select City" }}
            </p>
            <div class="dropdown-options">
              <div
                class="dropdown-option"
                *ngFor="let city of cities"
                (click)="setCity(city)"
              >
                {{ city.name }}
              </div>
            </div>
          </button>
        </div>
        <div class="col-12 mt-4">
          <p class="color-header fw-700 mb-2 font-14">
            Postal Code <span class="text-danger">*</span>
          </p>
          <div class="input-shape p-3">
            <input
              formControlName="postalCode"
              placeholder="Enter Postal Code"
              class="font-14 w-100 color-text"
              type="tel"
              autocomplete="off"
              pattern="^[0-9,]*$"
              maxlength="6"
              name="postalCode"
              id="postalCode"
            />
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="card-backg mb-4 p-3">
            <div class="d-flex mb-3 justify-content-between align-items-center">
              <img
                loading="lazy"
                style="width: 42px"
                src="/assets/images/tier-3.svg"
                alt=""
              />
              <div>
                <button
                  class="p-2 font-14 fw-700 border-0 btn-upgrade"
                  (click)="
                    window.open(
                      verificationURL +
                        '?token=' +
                        globals.quickEncrypt(globals.user?.username),
                      '_blank'
                    )
                  "
                >
                  Upgrade
                </button>
              </div>
            </div>
            <p class="font-16 mt-1 fw-700 mb-0">Tier 3</p>
            <p class="font-14 fw-400 mb-0">
              Upgrade your account now by completing your identity verification.
            </p>
          </div>
        </div>
        <div class="col-12 mt-4">
          <button
            type="submit"
            class="createBtn"
            [disabled]="!walletForm.valid"
          >
            Create Wallet
          </button>
        </div>
      </div>

      <!-- Success Message -->
      <div *ngIf="success">
        <div
          class="align-items-center justify-content-center text-center"
          style="margin-top: 8rem"
        >
          <div>
            <img
              src="assets/images/Illustration.png"
              alt="Create Wallet"
              class="modal-image"
            />
            <h4 class="font-18 fw-700 mt-2">Wallet Created</h4>
            <p class="mb-0 font-14">
              Your Wallet has been created successfully, kindly fund your
              account with the details below.
            </p>
          </div>
          <div>
            <p
              class="col-12 bg-purple p-3 color-white round-12 border-0 font-16 mt-4 fw-700"
              data-bs-toggle="modal"
              data-bs-target="#fundWallet"
              (click)="toggleFundWalletFields(); closeBtn.click()"
              style="margin-top: 3rem; width: 100%; cursor: pointer"
            >
              Fund wallet
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</swap-modal>
