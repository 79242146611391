import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Offer, Wallet, WalletTransaction } from 'src/app/services/core/IApp';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'fund-wallet',
  templateUrl: './fund-wallet.component.html',
  styleUrls: ['./fund-wallet.component.scss'],
})
export class FundWalletComponent {
  @Input() modalId: string = '';

  @Input() walletTransaction: WalletTransaction | null = null;

  @Input() wallet: Wallet | null = null;

  @Output() emptyVariables: EventEmitter<boolean> = new EventEmitter<boolean>();

  completed: boolean = false;

  @Input() makeOfferView: boolean = true;

  @Input() displayEscrowFees: boolean = false;

  constructor(public globals: GlobalsService) {}

  onSubmit() {}
}
