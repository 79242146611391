import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { GlobalsService } from 'src/app/services/core/globals';

@Component({
  selector: 'verify-social',
  templateUrl: './verify-social.component.html',
  styleUrls: ['./verify-social.component.scss']
})
export class VerifySocialComponent implements OnChanges{
  @Input() modalId: string = '';

  @Output() verifySocial: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('closeModal') closeModal: any;


  ngOnChanges() {}

}
